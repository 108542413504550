import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./component/Dashboard/Dasboard.css";
import AdminLogin from "./component/admin-login/AdminLogin";
import Wallet from "./pages/wallet/Wallet";

const UnstatedWallet = lazy(() => import("./pages/wallet/UnstatedWallet"));
const DeductFund = lazy(() => import("./pages/AppUser/DeductFund"));
const Leads = lazy(() => import("./pages/Leads/Leads"));
const ReferralsPlans = lazy(() => import("./pages/referralList/referralsPlans/ReferralsPlans"));
const ReferralList = lazy(() => import("./pages/referralList/ReferralList"));
const Setting = lazy(() => import("./pages/setting/Setting"));
const Skeleton = lazy(() => import("./component/skeleton/Skeleton"));
const WithDrawWallet = lazy(() =>
  import("./pages/WithDrawWallet/WithDrawWallet")
);
const ProductTab = lazy(() =>
  import("./pages/Products/ProductAttributeTabs/ProductTab")
);
const Invoice = lazy(() => import("./component/Invoice/Invoice"));
const MasterInvoice = lazy(() => import("./component/Invoice/MasterInvoice"));
const AddVariant = lazy(() => import("./pages/Products/AddVariant/AddVariant"));
const CategoryBanner = lazy(() =>
  import("./pages/Slider/CategoryBanner/CategoryBanner")
);
const AddCategoryBanner = lazy(() =>
  import("./pages/Slider/CategoryBanner/AddCategoryBanner")
);
const EditCategoryBanner = lazy(() =>
  import("./pages/Slider/CategoryBanner/EditCategoryBanner")
);
const BlogsList = lazy(() => import("./pages/blogs/BlogsList"));
const AddBlogs = lazy(() => import("./pages/blogs/AddBlogs"));
const EditBlogs = lazy(() => import("./pages/blogs/EditBlogs"));
const TestimonialList = lazy(() =>
  import("./pages/testimonial/TestimonialList")
);
const AddTestimonial = lazy(() => import("./pages/testimonial/AddTestimonial"));

const HomePopUpImg = lazy(() => import("./pages/homePopUpImg/HomePopUpImg"));
const MobileSliderList = lazy(() =>
  import("./pages/Slider/mobileSlider/MobileSliderList")
);
const AddMobileSlider = lazy(() =>
  import("./pages/Slider/mobileSlider/AddMobileSlider")
);

const CareInstruction = lazy(() =>
  import("./pages/careInstruction/CareInstruction")
);
const About = lazy(() => import("./pages/aboutUs/About"));
const AddTeam = lazy(() => import("./pages/aboutUs/AddTeam"));
const EditTeam = lazy(() => import("./pages/aboutUs/EditTeam"));
const UpdateSubscribeMail = lazy(() =>
  import("./pages/SubscribeMail/UpdateSubscribeMail")
);
const AddFaq = lazy(() => import("./pages/faq/AddFaq"));
const Faq = lazy(() => import("./pages/faq/Faq"));
const EditFaq = lazy(() => import("./pages/faq/EditFaq"));
const ImagesSetting = lazy(() => import("./pages/imagesSetting/ImagesSetting"));
const SimInvoice = lazy(() => import("./component/simInvoice/SimInvoice"));

const Configuration = lazy(() => import("./pages/Configuration/Configuration"));
const AddConfiguration = lazy(() =>
  import("./pages/Configuration/AddConfiguration")
);
const EditConfiguration = lazy(() =>
  import("./pages/Configuration/EditConfiguration")
);
const SimLead = lazy(() => import("./pages/SimCard/SimLead/SimLead"));
const SimBannerList = lazy(() =>
  import("./pages/SimCard/SimBanner/SimBannerList")
);
const AddSimBanner = lazy(() =>
  import("./pages/SimCard/SimBanner/AddSimBanner")
);
const EditSimBanner = lazy(() =>
  import("./pages/SimCard/SimBanner/EditSimBanner")
);
const FancyNumber = lazy(() =>
  import("./pages/SimCard/FancyNumber/FancyNumber")
);
const AddFancyNumber = lazy(() =>
  import("./pages/SimCard/FancyNumber/AddFancyNumber/AddFancyNumber")
);
const EditFancyNumber = lazy(() =>
  import("./pages/SimCard/FancyNumber/EditFancyNumber/EditFancyNumber")
);
const SimOperatorPlans = lazy(() =>
  import("./pages/SimCard/OperatorPlans/SimOperatorPlans")
);
const AddSimOperatorPlans = lazy(() =>
  import(
    "./pages/SimCard/OperatorPlans/AddSimOperatorPlans/AddSimOperatorPlans"
  )
);
const EditSimOperatorPlans = lazy(() =>
  import(
    "./pages/SimCard/OperatorPlans/EditSimOperatorPlans/EditSimOperatorPlans"
  )
);
const SimOperator = lazy(() => import("./pages/SimCard/Operator/SimOperator"));
const AddSimOperator = lazy(() =>
  import("./pages/SimCard/Operator/AddSimOperator/AddSimOperator")
);
const EditSimOperator = lazy(() =>
  import("./pages/SimCard/Operator/EditSimOperator/EditSimOperator")
);
const SimCategories = lazy(() =>
  import("./pages/SimCard/SimCategories/SimCategories")
);
const AddSimCategories = lazy(() =>
  import("./pages/SimCard/SimCategories/AddSimCategories/AddSimCategories")
);
const EditSimCategories = lazy(() =>
  import("./pages/SimCard/SimCategories/EditSimCategories/EditSimCategories")
);

const ReturnReplace = lazy(() =>
  import("./pages/Orders/ReturnReplaceOrder/ReturnReplace")
);
const EditPageBanner = lazy(() => import("./pages/PageBanner/EditPageBanner"));
const EnquiryMailList = lazy(() => import("./pages/Enquiry/EnquiryMailList"));
const UpdateEnquiryMail = lazy(() =>
  import("./pages/Enquiry/UpdateEnquiryMail")
);
const SubscribeMailList = lazy(() =>
  import("./pages/SubscribeMail/SubscribeMailList")
);
const FaqCategory = lazy(() => import("./pages/faq/FaqCategory/FaqCategory"));

const AddFaqCategory = lazy(() =>
  import("./pages/faq/FaqCategory/AddFaqCategory")
);

const EditFaqCategory = lazy(() =>
  import("./pages/faq/FaqCategory/EditFaqCategory")
);

const PageBannerList = lazy(() => import("./pages/PageBanner/PageBannerList"));
const AddPageBanner = lazy(() => import("./pages/PageBanner/AddPageBanner"));
const VendorProducts = lazy(() =>
  import("./pages/VendorProduct/VendorProducts")
);
const AddVendorProducts = lazy(() =>
  import("./pages/VendorProduct/AddProducts/AddVendorProducts")
);
const EditVendorProducts = lazy(() =>
  import("./pages/VendorProduct/EditProducts/EditVendorProducts")
);
const ChangePassword = lazy(() => import("./component/Profile/ChangePassword"));
const AddList = lazy(() => import("./pages/SubPages/AddList"));
const EditAttribute = lazy(() =>
  import("./pages/Products/EditAttribute/EditAttribute")
);
const EditTestimonial = lazy(() =>
  import("./pages/testimonial/EditTestimonial")
);

const EditMobileSlider = lazy(() =>
  import("./pages/Slider/mobileSlider/EditMobileSlider")
);
const Warratny_Return = lazy(() => import("./pages/Warranty/Warratny_Return"));
const TimeSlot = lazy(() =>
  import("./pages/DeliverySchedule/TimeSlot/TimeSlot")
);
const ClosingHours = lazy(() =>
  import("./pages/DeliverySchedule/ClosingHours/ClosingHours")
);
const Stock_Update = lazy(() => import("./pages/StockUpdate/Stock_Update"));
const Edit_Stock = lazy(() =>
  import("./pages/StockUpdate/EditStock/Edit_Stock")
);
const ListDeliveryBoy = lazy(() =>
  import("./pages/DeliveryBoy/ListDeliveryBoy")
);

const AddDeliveryBoy = lazy(() =>
  import("./pages/DeliveryBoy/AddDeliveryBoy/AddDeliveryBoy")
);
const EditDeliveryBoy = lazy(() =>
  import("./pages/DeliveryBoy/EditDeliveryBoy/EditDeliveryBoy")
);
const Coupon = lazy(() => import("./pages/Coupon/Coupon"));
const AddCoupon = lazy(() => import("./pages/Coupon/AddCoupon/AddCoupon"));
const EditCoupon = lazy(() => import("./pages/Coupon/EditCoupon/EditCoupon"));
const Cashback = lazy(() => import("./pages/Cashback/Cashback"));
const Deal_Products = lazy(() => import("./pages/Deal_Products/Deal_Products"));
const AddDealProducts = lazy(() =>
  import("./pages/Deal_Products/AddDealProduct/AddDealProducts")
);
const EditDealProducts = lazy(() =>
  import("./pages/Deal_Products/EditDealProducts/EditDealProducts")
);
const RaiseTicket = lazy(() => import("./pages/RaiseTicket/RaiseTicket"));

// lazy loading for this pages only
const Home = lazy(() => import("./pages/Home/Home"));
const Categories = lazy(() => import("./pages/AllCategories/Categories"));
const AddCategories = lazy(() =>
  import("./pages/AllCategories/AddCategories/AddCategories")
);
const EditCategories = lazy(() =>
  import("./pages/AllCategories/EditCategories/EditCategories")
);
const AllOrder = lazy(() => import("./pages/Orders/AllOrders/AllOrder"));

const Area = lazy(() => import("./pages/Area/Area"));
const AreaList = lazy(() => import("./pages/Area/AreaList/AreaList"));
const EditArea = lazy(() => import("./pages/Area/EditArea/EditArea"));
const ProductOrder = lazy(() => import("./pages/PurchesOrder/ProductOrder"));
const AllPage = lazy(() => import("./pages/SubPages/List"));
const ListEdit = lazy(() => import("./pages/SubPages/ListEdit"));
const Declared_Reward = lazy(() =>
  import("./pages/Declared_Reward/Declared_Reward")
);
const ReferalAmount = lazy(() => import("./pages/ReferalAmount/ReferalAmount"));
const WelcomeBonus = lazy(() => import("./pages/WelcomeBonus/WelcomeBonus"));
const OrderLimitSetting = lazy(() =>
  import("./pages/OrderLimitSetting/OrderLimitSetting")
);
const EditOrderLimit = lazy(() =>
  import("./pages/OrderLimitSetting/EditOrderLimit/EditOrderLimit")
);
const Offers = lazy(() => import("./pages/Offers/Offers"));
const EditOffer = lazy(() => import("./pages/Offers/EditOffer"));
const Stock = lazy(() => import("./pages/Stock/Stock"));
const Notification = lazy(() => import("./pages/Notification/Notification"));
const MainSlider = lazy(() => import("./pages/Slider/MainSlider/MainSlider"));
const EditMainSlider = lazy(() =>
  import("./pages/Slider/MainSlider/EditMainSlider")
);
const AddMainSlider = lazy(() =>
  import("./pages/Slider/MainSlider/AddMainSlider")
);
const FeatureBanner = lazy(() =>
  import("./pages/Slider/FeatureBrand/FeatureBanner")
);
const AddFeatureBanner = lazy(() =>
  import("./pages/Slider/FeatureBrand/AddFeatureBanner")
);
const EditFeatureBanner = lazy(() =>
  import("./pages/Slider/FeatureBrand/EditFeatureBanner")
);
const WebBanner = lazy(() => import("./pages/Slider/WebsiteBanner/WebBanner"));
const Promotion = lazy(() =>
  import("./pages/Slider/PromotionSection/Promotion")
);
const AppUser = lazy(() => import("./pages/AppUser/AppUser"));
const User_action = lazy(() => import("./pages/AppUser/User_action"));
const Products = lazy(() => import("./pages/Products/Products"));
const AddProducts = lazy(() =>
  import("./pages/Products/AddProducts/AddProducts")
);
const Bulk_Upload = lazy(() =>
  import("./pages/Products/BulkUpload/Bulk_Upload")
);
const Edit_Products = lazy(() =>
  import("./pages/Products/EditProducts/Edit_Products")
);
const AddImageList = lazy(() =>
  import("./pages/Products/AddImage/AddImageList")
);
const Product_Category = lazy(() =>
  import("./pages/Products/Product_Category/Product_Category")
);

const Add_Coupon = lazy(() => import("./pages/Products/AddCoupon/Add_Coupon"));

// services

const Service = lazy(() => import("./pages/services/Services"));
const AddServices = lazy(() =>
  import("./pages/services/addServices/AddServices")
);
const EditServices = lazy(() =>
  import("./pages/services/editServices/EditServices")
);

// catalogue
const Catalogue = lazy(() => import("./pages/Catalogue/Catalogue"));
const AddCatalogue = lazy(() =>
  import("./pages/Catalogue/AddCatalogue/AddCatalogue")
);
const EditCatalogue = lazy(() =>
  import("./pages/Catalogue/EditCatalogue/EditCatalogue")
);

const Our_Achievements = lazy(() =>
  import("./pages/Our_Achievements/Our_Achievements")
);
const EditProfile = lazy(() => import("./component/Profile/EditProfile"));

// vendor
const VendorList = lazy(() => import("./pages/Vendor/VendorList/VendorList"));
const Add_Vendor = lazy(() => import("./pages/Vendor/AddVendor/Add_Vendor"));
const Edit_Vendor = lazy(() => import("./pages/Vendor/EditVendor/Edit_Vendor"));
const AddBulkProduct = lazy(() =>
  import("./pages/Products/addBulkProduct/AddBulkProduct")
);

const UnVerifiedProduct = lazy(() =>
  import("./pages/Products/UnVerifiedProduct/UnVerifiedProduct")
);

const Subscription = lazy(() => import("./pages/subscription/Subscription"));
const CreateSubscription = lazy(() =>
  import("./pages/subscription/CreateSubscription")
);
const UpdateSubscription = lazy(() =>
  import("./pages/subscription/UpdateSubscription")
);

const SellerPlans = lazy(() =>
  import("./pages/Vendor/sellerPlans/SellerPlans")
);

const Routing = () => {
  return (
    <Suspense
      fallback={
        <div className="lazy-loading-main d-flex justify-content-center align-items-center">
          {/* <img
            src={require("./component/assets/images/latest-logo.png")}
            alt="loading"
            className="img-fluid"
          /> */}
          {/* <div className="loader"></div> */}
        </div>
      }
    >
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/login" element={<AdminLogin />}></Route>
        <Route path="/all_categories" element={<Categories />}></Route>
        <Route path="/add_categories" element={<AddCategories />}></Route>
        <Route path="/edit_categories/:id" element={<EditCategories />}></Route>
        {/* orders */}
        <Route path="/orders" element={<AllOrder />}></Route>
        <Route path="/referral-list" element={<ReferralList />}></Route>

        {/* return replace list */}
        <Route path="/return_replace" element={<ReturnReplace />}></Route>
        {/* subscription */}
        <Route path="/subscription" element={<Subscription />}></Route>
        <Route
          path="/create/subscription"
          element={<CreateSubscription />}
        ></Route>
        <Route
          path="/update/subscription/:id"
          element={<UpdateSubscription />}
        ></Route>

        {/* app users */}
        <Route path="/app_user" element={<AppUser />}></Route>
        <Route path="/wallet" element={<Wallet />}></Route>
        <Route path="/withdraw_wallet" element={<WithDrawWallet />}></Route>
        <Route path="/unstated_wallet" element={<UnstatedWallet />}></Route>
        <Route path="/user_action/:id" element={<User_action />}></Route>
        <Route path="/deduct_fund/:id" element={<DeductFund />}></Route>

        {/* Vendor */}
        <Route path="/seller_list" element={<VendorList />}></Route>
        <Route
          path="/seller/product/bulk/:vendorId"
          element={<AddBulkProduct />}
        ></Route>
        <Route path="/add_seller" element={<Add_Vendor />}></Route>
        <Route path="/edit_seller/:id" element={<Edit_Vendor />}></Route>

        {/* Area */}
        <Route path="/area" element={<Area />}></Route>
        <Route path="/area_list" element={<AreaList />}></Route>
        <Route path="/edit_area/:id" element={<EditArea />}></Route>
        {/* product order */}
        <Route path="/product_order" element={<ProductOrder />}></Route>

        {/* manage product */}
        <Route path="/products" element={<Products />}></Route>
        <Route path="/products_tab/:id" element={<ProductTab />}></Route>
        <Route path="/add_products" element={<AddProducts />}></Route>
        <Route
          path="/edit_products/:id"
          // path="/edit_products/:id/:pageCount"
          element={<Edit_Products />}
        ></Route>
        <Route path="/edit_attribute/:id" element={<EditAttribute />}></Route>
        <Route path="/bulk_upload" element={<Bulk_Upload />}></Route>
        <Route path="/add_image_list/:id" element={<AddImageList />}></Route>
        <Route
          path="/product_category/:id"
          element={<Product_Category />}
        ></Route>

        {/* vendor product */}
        <Route path="/seller_products/:id" element={<VendorProducts />}></Route>
        <Route
          path="/products/unverified"
          element={<UnVerifiedProduct />}
        ></Route>
        <Route
          path="/add_seller_products/:id"
          element={<AddVendorProducts />}
        ></Route>
        <Route
          path="/edit_seller_product/:id"
          element={<EditVendorProducts />}
        ></Route>
         <Route path="/seller/plans/:userId" element={<SellerPlans />}></Route>
         <Route path="/referral/plans/:userId" element={<ReferralsPlans />}></Route>
         <Route path="/leads" element={<Leads />}></Route>

        <Route path="/add_coupon" element={<Add_Coupon />}></Route>

        <Route path="/home_popup_img" element={<HomePopUpImg />}></Route>

        {/* delivery schedule hours */}
        <Route path="/time_slot" element={<TimeSlot />}></Route>
        <Route path="/closing_hours" element={<ClosingHours />}></Route>

        {/* stock update */}
        <Route path="/stock_update" element={<Stock_Update />}></Route>
        <Route path="/edit_stock" element={<Edit_Stock />}></Route>

        {/* Delivery boy list */}
        <Route path="/list-delivery-boy" element={<ListDeliveryBoy />}></Route>
        <Route path="/add-delivery-boy" element={<AddDeliveryBoy />}></Route>
        <Route
          path="/edit-delivery-boy/:id"
          element={<EditDeliveryBoy />}
        ></Route>

        {/* All Page App */}
        <Route path="/list" element={<AllPage />}></Route>
        <Route path="/add_list" element={<AddList />}></Route>
        <Route path="/list_edit/:id" element={<ListEdit />}></Route>

        {/* website banner */}
        <Route path="/banner_list" element={<PageBannerList />}></Route>
        <Route path="/add_page_banner" element={<AddPageBanner />}></Route>
        <Route
          path="/edit_page_banner/:id"
          element={<EditPageBanner />}
        ></Route>

        <Route path="/declared_reward" element={<Declared_Reward />}></Route>
        <Route path="/refer" element={<ReferalAmount />}></Route>
        <Route path="/welcome_bonus" element={<WelcomeBonus />}></Route>
        <Route
          path="/order_limit_setting"
          element={<OrderLimitSetting />}
        ></Route>
        <Route path="/edit_order_setting" element={<EditOrderLimit />}></Route>
        {/* offers */}
        <Route path="/offer" element={<Offers />}></Route>
        <Route path="/edit_offer/:id" element={<EditOffer />}></Route>
        {/* stock */}
        <Route path="/stock" element={<Stock />}></Route>
        <Route path="/notification" element={<Notification />}></Route>
        {/* blogs pages */}
        <Route path="/blogs" element={<BlogsList />}></Route>
        <Route path="/add_blogs" element={<AddBlogs />}></Route>
        <Route path="/edit_blog/:id" element={<EditBlogs />}></Route>
        {/* blogs pages */}
        <Route path="/testimonial" element={<TestimonialList />}></Route>
        <Route path="/add_testimonial" element={<AddTestimonial />}></Route>
        <Route
          path="/edit_testimonial/:id"
          element={<EditTestimonial />}
        ></Route>
        {/* slider pages */}
        <Route path="/main_slider" element={<MainSlider />}></Route>
        <Route
          path="/edit_main_slider/:id"
          element={<EditMainSlider />}
        ></Route>
        <Route path="/add_main_slider" element={<AddMainSlider />}></Route>
        {/* mobile slider */}
        <Route path="/mobile_slider" element={<MobileSliderList />}></Route>
        <Route path="/add_mobile_slider" element={<AddMobileSlider />}></Route>
        <Route
          path="/edit_mobile_slider/:id"
          element={<EditMobileSlider />}
        ></Route>
        <Route path="/feature_brand_slider" element={<FeatureBanner />}></Route>
        <Route
          path="/add_feature_banner"
          element={<AddFeatureBanner />}
        ></Route>
        <Route
          path="/edit_feature_banner"
          element={<EditFeatureBanner />}
        ></Route>
        <Route path="/web_banner" element={<WebBanner />}></Route>
        <Route path="/promotion_section" element={<Promotion />}></Route>

        {/* category banner */}
        <Route path="/category_banner" element={<CategoryBanner />}></Route>
        <Route
          path="/edit_category_banner/:id"
          element={<EditCategoryBanner />}
        ></Route>
        <Route
          path="/add_category_banner"
          element={<AddCategoryBanner />}
        ></Route>

        {/* coupon */}
        <Route path="/coupons" element={<Coupon />}></Route>
        <Route path="/addCoupon" element={<AddCoupon />}></Route>
        <Route path="/edit_coupon/:id" element={<EditCoupon />}></Route>
        <Route path="/cashback" element={<Cashback />}></Route>
        <Route path="/deal_products" element={<Deal_Products />}></Route>
        <Route path="/add_deal_products" element={<AddDealProducts />}></Route>
        <Route
          path="/edit_deal_products"
          element={<EditDealProducts />}
        ></Route>
        <Route path="/raise_ticket" element={<RaiseTicket />}></Route>
        <Route path="/ske" element={<Skeleton />}></Route>

        {/* services */}
        <Route path="/all_services" element={<Service />}></Route>
        <Route path="/add_services" element={<AddServices />}></Route>
        <Route path="/edit_services/:id" element={<EditServices />}></Route>

        {/*Catalogue  */}
        <Route path="/catalogue" element={<Catalogue />}></Route>
        <Route path="/add_catalogue" element={<AddCatalogue />}></Route>
        <Route path="/edit_catalogue/:id" element={<EditCatalogue />}></Route>
        {/* profile */}
        <Route path="/edit_profile" element={<EditProfile />}></Route>
        <Route path="/change_password" element={<ChangePassword />}></Route>
        {/* order details */}
        <Route
          path="/master/order_details/:id"
          element={<MasterInvoice />}
        ></Route>
        <Route path="/order_details/:id" element={<Invoice />}></Route>
        <Route path="/add_variant/:id" element={<AddVariant />}></Route>
        <Route
          path="/warranty_return_policy"
          element={<Warratny_Return />}
        ></Route>
        <Route path="/care-instruction" element={<CareInstruction />}></Route>
        <Route path="/our_achievements" element={<Our_Achievements />}></Route>

        {/* about us */}
        <Route path="/about" element={<About />}></Route>
        <Route path="/add_team" element={<AddTeam />}></Route>
        <Route path="/edit_team/:id" element={<EditTeam />}></Route>

        {/* Subscribe Mail */}
        <Route path="/subscribe-mail" element={<SubscribeMailList />}></Route>
        <Route
          path="/update-subscribe-mail/:id"
          element={<UpdateSubscribeMail />}
        ></Route>

        {/* Enquires */}
        <Route path="/enquiry-mail" element={<EnquiryMailList />}></Route>
        <Route
          path="/update-enquiry-mail/:id"
          element={<UpdateEnquiryMail />}
        ></Route>

        {/* faq category */}
        <Route path="/faq_category" element={<FaqCategory />}></Route>
        <Route path="/add-faq-category" element={<AddFaqCategory />}></Route>
        <Route
          path="/edit-faq-category/:id"
          element={<EditFaqCategory />}
        ></Route>

        {/* faq list */}
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/add-faq" element={<AddFaq />}></Route>
        <Route path="/edit-faq/:id" element={<EditFaq />}></Route>
        <Route path="/img-setting" element={<ImagesSetting />}></Route>

        {/* sim card */}

        {/* operator */}
        <Route path="/sim_operator" element={<SimOperator />}></Route>
        <Route path="/add_sim_operator" element={<AddSimOperator />}></Route>
        <Route
          path="/edit_sim_operator/:id"
          element={<EditSimOperator />}
        ></Route>

        {/* operator plans */}
        <Route
          path="/sim_operator_plans"
          element={<SimOperatorPlans />}
        ></Route>
        <Route
          path="/add_sim_operator_plans"
          element={<AddSimOperatorPlans />}
        ></Route>
        <Route
          path="/edit_sim_operator_plans/:id"
          element={<EditSimOperatorPlans />}
        ></Route>

        {/* category */}
        <Route path="/sim_categories" element={<SimCategories />}></Route>
        <Route
          path="/add_sim_categories"
          element={<AddSimCategories />}
        ></Route>
        <Route
          path="/edit_sim_categories/:id"
          element={<EditSimCategories />}
        ></Route>

        {/* fancy number */}
        <Route path="/fancy_number" element={<FancyNumber />}></Route>
        <Route path="/add_fancy_number" element={<AddFancyNumber />}></Route>
        <Route
          path="/edit_fancy_number/:id"
          element={<EditFancyNumber />}
        ></Route>

        {/* sim banner */}
        <Route path="/sim_banner_list" element={<SimBannerList />}></Route>
        <Route path="/add_sim_banner" element={<AddSimBanner />}></Route>
        <Route path="/edit_sim_banner/:id" element={<EditSimBanner />}></Route>
        <Route path="/sim_lead" element={<SimLead />}></Route>
        <Route path="/lead/invoice/:simLeadId" element={<SimInvoice />}></Route>
        {/* configruation */}
        <Route path="/configuration" element={<Configuration />}></Route>
        <Route path="/add_configuration" element={<AddConfiguration />}></Route>
        <Route path="/setting" element={<Setting />}></Route>
        <Route
          path="/edit_configuration/:id"
          element={<EditConfiguration />}
        ></Route>
      </Routes>
    </Suspense>
  );
};

export default Routing;
