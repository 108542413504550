// Skeleton.js
import React from "react";
import "./style.css";

const Skeleton = () => {
  const data = ["1"];
  return (
    <section className="skeleton-main ">
      <div className="container-fluid">
        <div className="row ske_row">
          {data.map((elem, index) => {
            return (
              <div key={index} className="col-lg-12 ske_col">
                <div className="skeleton-title"></div>
                <div className="skeleton-card">
                  <div className="skeleton-image"></div>
                  {/* <div className="skeleton-details">
                    <div className="skeleton-title"></div>
                    <div className="skeleton-description"></div>
                    <div className="skeleton-title"></div>
                    <div className="skeleton-description"></div>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skeleton;
