/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import AssignmentIcon from "@mui/icons-material/Assignment";
import "../AppUser/AppUser.css";
import "../Home/Home.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";
import axios from "axios";
import Skeleton from "../../component/skeleton/Skeleton";
import { debounce } from "lodash";
import { ToastContainer, toast } from "react-toastify";
const Wallet = () => {
  const [isLoading, setIsLoading] = useState(true);
  const ArrowBackIcon = () => <span>Previous</span>;
  const ArrowForwardIcon = () => <span>Next</span>;
  const [search, setSearch] = useState("");
  const [appUserData, setAppUserData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [filterParameter, setFilterParameter] = useState("first_name");
  const [sorting, setSorting] = useState({
    column: "id",
    ascending: true,
  });
  const [itemCount, setItemCount] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState("");
  let PAGE_LIMIT = "10";
  const handlePageChange = (event, newPage) => {
    setPageCount(newPage);
  };

  // Memoize the parameters for the API request
  const memoizedParams = useMemo(
    () => ({
      limit: PAGE_LIMIT,
      page: pageCount,
      sortBy: sorting.column,
      order: sorting.ascending ? "ASC" : "DESC",
      filter_parameter: filterParameter,
      filter_value: search,
    }),
    [PAGE_LIMIT, pageCount, sorting.column, sorting.ascending, search]
  );

  // Debounced fetch function
  const fetchUsers = useCallback(
    debounce(async (params) => {
      setIsLoading(true);
      try {
        const filteredParams = {};
        Object.keys(params).forEach((key) => {
          if (params[key]) {
            filteredParams[key] = params[key];
          }
        });
        const response = await axios.get(API_BASE_URL + "admin/user", {
          params: filteredParams,
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        });
        if (response.data.status) {
          setIsLoading(false);

          setAppUserData(response.data.data);
          setItemCount(response.data.pagination.total);
          setNumberOfPages(response.data.pagination.totalPages);
        } else {
          setAppUserData([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.log("error occured fetching data", error);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  ); // 300ms debounce delay

  // Use effect to fetch categories when memoized parameters change
  useEffect(() => {
    fetchUsers(memoizedParams);
  }, [memoizedParams, fetchUsers]);

  const handleDataRefresh = async () => {
    await fetchUsers(memoizedParams);
  };

  // user delete
  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure want to delete these user");
    if (!confirm) {
      return;
    }
    try {
      const response = await axios.delete(
        API_BASE_URL + `admin/user/delete/${id}`,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.messsage);
        const updatedUser = appUserData.filter(
          (item) => item.product_id !== id
        );
        setAppUserData(updatedUser);
        handleDataRefresh();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("error occured delete product", error);
    }
  };

  // sorting
  const handleSort = (columnName) => {
    if (columnName === sorting.column) {
      setSorting({ ...sorting, ascending: !sorting.ascending });
    } else {
      setSorting({ column: columnName, ascending: true });
    }
  };
  const renderArrow = (columnName) => {
    if (sorting.column === columnName) {
      return sorting.ascending ? (
        <img
          src={require("../../component/assets/images/tables-icon/icon_sorting1.png")}
          className="img-fluid"
          alt=""
        />
      ) : (
        <img
          src={require("../../component/assets/images/tables-icon/icon_sorting2.png")}
          className="img-fluid"
          alt=""
        />
      );
    }
    return (
      <>
        <img
          src={require("../../component/assets/images/tables-icon/icon_sorting2.png")}
          className="img-fluid"
          alt=""
        />
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Buyer Wallet | Pure Desi Store</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="today_order_table">
              <div className="table_body_order">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="cart_heading">
                      <AssignmentIcon className="fs-2" />
                      <h6 className="today_heading">Buyer Wallet</h6>
                      <div className="show_entreis">{itemCount}</div>
                      <h6 className="show_entry_heading">Show entries</h6>
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex justify-content-end align-items-center">
                    <div className={`today_order_Search me-1 `}>
                      <select
                        name="filterParameter"
                        value={filterParameter}
                        className={`ps-2 pe-2 form-control rounded-2`}
                        style={{ appearance: "auto" }}
                        onChange={(e) => setFilterParameter(e.target.value)}
                      >
                        <option value="first_name">Name</option>
                        <option value="phone">Phone</option>
                        <option value="email">Email</option>
                      </select>
                    </div>
                    <div className="today_order_Search me-1">
                      <input
                        type="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="form-control search_input1"
                      />
                      {search ? "" : <SearchIcon className="search_icon1" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <div className="col-lg-12">
              <table className="table table-striped mt-3">
                <thead>
                  <tr className="main_table_head">
                    <th scope="col" onClick={() => handleSort("id")}>
                      User ID {renderArrow("id")}
                    </th>
                    <th scope="col" onClick={() => handleSort("first_name")}>
                      Name {renderArrow("first_name")}
                    </th>
                    <th scope="col" onClick={() => handleSort("phone")}>
                      Phone {renderArrow("phone")}
                    </th>
                    <th scope="col" onClick={() => handleSort("email")}>
                      User Email {renderArrow("email")}
                    </th>
                    <th scope="col" onClick={() => handleSort("total_order")}>
                      Wallet Amount {renderArrow("total_order")}
                    </th>
                    <th scope="col" onClick={() => handleSort("status")}>
                      Status {renderArrow("status")}
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className="main_body_table">
                  {appUserData.length <= 0 ? (
                    <tr>
                      <td colSpan="11">
                        <img
                          src={require("../../component/assets/images/nodatafound.png")}
                          className="img-fluid"
                          alt="noDataFound"
                          width={200}
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {appUserData.map((item, index) => {
                        const currentIndex = (pageCount - 1) * 8 + index + 1;
                        return (
                          <tr key={item.id}>
                            <th scope="row">{currentIndex}</th>
                            <td>
                              {item.first_name} {item.last_name}
                            </td>
                            <td>
                              {" "}
                              <span className="text-center">{item.phone}</span>
                            </td>
                            <td>
                              <span className="text-center">{item.email}</span>{" "}
                            </td>
                            <td>{item.wallet}</td>
                            <td>
                              {item.status === "Y" ? (
                                <span className="confirm_status">ACTIVE</span>
                              ) : item.status === "N" ? (
                                <span className="cancel_status">DEACTIVE</span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <Link to={`/user_action/${item.id}`}>
                                <img
                                  src={require("../../component/assets/images/edit-icon.png")}
                                  className="img-fluid confirm_img"
                                  alt=""
                                />
                              </Link>
                              <Link
                                to=""
                                className="ms-2"
                                onClick={() => handleDelete(item.id)}
                              >
                                <img
                                  src={require("../../component/assets/images/cancel.png")}
                                  className="img-fluid confirm_img"
                                  alt=""
                                />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
              <div className="pagination_table">
                <Stack spacing={2}>
                  <Pagination
                    count={numberOfPages}
                    page={pageCount}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </div>
            </div>
          )}
        </div>
      </div>

      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default Wallet;
